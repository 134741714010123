import React from 'react'
import Resume from '../../Components/Resume/Resume'

export default function ResumePage(props) {
  
  return (
    <div className="resumePageContainer">
      <Resume/>
    </div>
  )
}